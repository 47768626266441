<template>
  <div style="background: #404040; height: 100%">
    <Header class="header" style="background: #fff;"></Header>
    <div class="login-container" id="content">
      <div class="login-img_bg" id="login_img"/>
      <div style="width: 1140px; height: 100%; margin: 0 auto; position: relative">
        <a-form
          ref="form"
          :form="form"
          class="login-form"
          id="login"
          @submit="handleSubmitV2"
        >
          <h3 class="title">用户登录</h3>
          <a-tabs default-active-key="1" @change="registerCodeCheck">
            <a-tab-pane key="1" tab="密码登录">
              <a-form-item>
                <a-input
                  size="large"
                  type="text"
                  placeholder="请输入用户名"
                  v-decorator="[
                'email',
                {
                  rules: [
                    { required: true, type: '', whitespace:true,message: '请输入用户名' },
                  ],
                  validateTrigger: ['change', 'blur'],
                },
              ]"
                >
                  <img class="img1" :src="user" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input-password
                  size="large"
                  placeholder="请输入密码"
                  v-decorator="[
                'password',
                {
                  rules: [{ required: true,whitespace:true, message: '请输入密码' }],
                  validateTrigger: 'blur',
                },
              ]"
                >
                  <img class="img2" :src="mima" slot="prefix" alt=""/>
                </a-input-password>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="2" tab="验证码登录" force-render>
              <a-form-item class="emailForm">
                <a-input size="large" type="text" placeholder="请输入手机号"
                         v-decorator="['mobile', {rules: [{ required: true, message: '请输入手机号！' },{ pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                  <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="14">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="请输入验证码"
                             v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]">
                      <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="10">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getPhoneCaptcha"
                    v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                  ></a-button>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>

          <div class="remember">
            <a-checkbox v-model="checked">记住用户名</a-checkbox>
            <router-link
              :to="{ name: 'forgetPassword', params: { user: 'aaa' } }"
              class="forge-password"
              style="float: right"
            >忘记密码
            </router-link
            >
          </div>
          <div>
            <a-button
              class="submit"
              size="large"
              type="primary"
              htmlType="submit"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >登录
            </a-button
            >
          </div>
          <div class="user-login"> 还没有账号，去
            <router-link class="register" :to="{ name: 'register' }">注册</router-link>
          </div>
          <div class="user-login-other">
            <!-- <span>其他登录方式</span>
                <a class="chat">
                <a-icon type="wechat" />
                </a> -->
          </div>
          <div class="agree">
            <span class="text1">登录即代表您同意 </span>
            <span class="text2" @click="goAgree">用户协议</span>
            <span class="text1"> 和 </span>
            <span class="text2" @click="goSecret"> 隐私政策</span>
          </div>
        </a-form>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import user from '../../assets/img/55.png'
import mima from '../../assets/img/56.png'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import md5 from '@/utils/MD5.js'

export const getUserInfo = () => {
  const userStr = window.localStorage.getItem('user_info')
  let user = null
  try {
    user = JSON.parse(userStr)
  } catch (e) {
  }
  return user
}

export const clearUserInfo = () => {
  window.localStorage.removeItem('user_info')
}

const setUserInfo = (user) => {
  let userStr = null
  try {
    userStr = JSON.stringify(user)
  } catch (e) {
  }
  userStr && window.localStorage.setItem('user_info', userStr)
}
export default {
  name: 'login',
  data () {
    return {
      user,
      mima,
      md5,
      form: {
        username: '',
        password: ''
      },
      // 登录状态，包括倒计时、登录按钮状态和短信发送按钮状态
      state: {
        time: 180,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      // 验证规则，用于表单数据的合法性校验
      validatorRules: {
        phone: {
          rules: [
            { required: true, message: '请输入联系人电话!' },
            { validator: this.validateMobile }
          ]
        }
      },
      // 记录用户是否选中记住密码
      checked: true,
      // 用户ID，用于登录成功后的用户标识
      userId: '',
      // 加密密钥，用于密码加密
      encryptionKey: '',
      // 注册类型，决定登录方式是密码登录还是手机登录
      // 1 默认是密码 2 按照手机
      registerType: '1',
      // 角色标识，用于区分用户角色
      roleFlag: false
    }
  },
  created () {
    this.form = this.$form.createForm(this, { name: 'register' })
  },
  mounted () {
    this.form.resetFields()
    this.getCookie()
    this.getHeight()
    this.getPublicSecret()
  },
  methods: {
    registerCodeCheck (key) {
      this.registerType = key
      console.log('注册类型：', this.registerType)
    },
    // 验证手机号的格式是否正确
    validateMobile (rule, value, callback) {
      if (
        !value ||
        new RegExp(
          /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
        ).test(value)
      ) {
        callback()
      } else {
        callback('您的手机号码格式不正确!')
      }
    },
    getPhoneCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      validateFields(['mobile'], { force: true },
        (err, values) => {
          if (!err) {
            const hide = $message.loading('验证码发送中..', 0)
            const interval = window.setInterval(() => {
              // console.log('手机注册-发送验证码', values)
              if (state.time-- <= 0) {
                state.time = 180
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)
            // 手机登录-发送验证码
            // 参数
            const emailCodeParam = {
              client_id: this.$clientId,
              client_secret: this.$clientSecret,
              phone: values.mobile,
              sendType: '2',
              time: (new Date()).valueOf()
            }
            const sign = md5.paramMd5(emailCodeParam, 'client_secret', 'hytcebook.7,f@e')
            emailCodeParam.sign = sign
            // console.log('手机登录-发送验证码1', emailCodeParam)
            const _this = this
            _this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/sendPhoneVerCode',
              emailCodeParam
            ).then(res => {
              if (res && res.data && res.data.resultData && res.data.resultCode === '0') {
                // console.log('手机注册-发送验证码', res.data)
                state.smsSendBtn = true
                setTimeout(hide, 500)
                $notification.success({
                  message: '提示',
                  description: '验证码获取成功,请您手机查看',
                  duration: 5
                })
              } else {
                clearInterval(interval)
                setTimeout(hide, 500)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 180
              state.smsSendBtn = false
              // this.requestFailed(err)
            })
          }
        })
    },
    handleSubmitV2 (e) {
      // 登录接口加密
      e.preventDefault()
      const _this = this
      _this.form.validateFieldsAndScroll((err, values) => {
        // 判断登录类型
        if (this.registerType === '1') {
          this.handleEmailLogin(values)
        } else {
          this.handlePhoneLogin(values)
        }
      }
      )
    },
    // 处理邮箱登录
    async handleEmailLogin (values) {
      if (values.email.trim() === '' || values.password === '') {
        return
      }
      // 设置cookie逻辑根据需要调用
      this.setCookieIfChecked(values.email, values.password)

      const param = this.buildParam(values.email.trim(), values.password)
      const sign = md5.paramMd5(param, 'client_secret', 'hytcebook.7,f@e')
      param.sign = sign
      try {
        const res = await this.$http.post(`${this.$loginServer}auth/res/v2/login`, param)
        if (res.data.resultCode === '0') {
          this.handleLoginSuccess(res.data.resultData, values.email.trim())
        } else {
          this.handleError(res.data.resultMessage)
        }
      } catch (error) {
        console.error('登录请求失败:', error)
        this.$notification.error({
          message: '提示',
          description: '网络请求失败，请稍后重试'
        })
      }
    },

    // 处理手机登录
    async handlePhoneLogin (values) {
      // console.log('手机登录-参数: ', values)
      if (!values.mobile || !values.captcha) {
        return
      }
      if (values.mobile.trim() === '' || values.captcha === '') {
        return
      }
      this.setCookieIfChecked(values.mobile, values.captcha)

      const param = this.buildParam(values.mobile.trim(), values.captcha)
      const sign = md5.paramMd5(param, 'client_secret', this.$clientSecret)
      param.sign = sign

      try {
        const res = await this.$http.post(`${this.$loginServer}auth/res/v2/phoneCodeLogin`, param)
        if (res.data.resultCode === '0') {
          // console.log('手机登录-返回结果: ', res.data)
          this.handleLoginSuccess(res.data.resultData, values.mobile.trim())
        } else {
          this.handleError(res.data.resultMessage)
        }
      } catch (error) {
        console.error('登录请求失败:', error)
        this.$notification.error({
          message: '提示',
          description: '网络请求失败，请稍后重试'
        })
      }
    },
    buildParam (phoneOrEmail, passwordOrCode) {
      const baseParam = {
        client_id: this.$clientId,
        client_secret: this.$clientSecret,
        time: new Date().valueOf()
      }

      if (this.registerType === '1') { // Email login
        return {
          ...baseParam,
          username: phoneOrEmail.trim(),
          password: passwordOrCode
        }
      } else { // Phone login
        return {
          ...baseParam,
          phone: phoneOrEmail.trim(),
          code: passwordOrCode,
          params: {
            successStrategyType: 0,
            roleType: 2
          }
        }
      }
    },
    handleLoginSuccess (decryptedData, identifier) {
      const hide = this.$message.loading('登录中..', 0)
      setTimeout(hide)
      this.$notification.success({
        message: '提示',
        description: '登录成功'
      })

      console.log('登录成功-解密后数据: ', decryptedData)
      // 存储用户信息和token
      this.userId = decryptedData.user.id
      setUserInfo(Object.assign(decryptedData.user, {
        user: [],
        access_token: decryptedData.tokenInfo.access_token
      }))
      localStorage.setItem('access_token', decryptedData.tokenInfo.access_token)
      localStorage.setItem('userId', decryptedData.user.id)

      // 角色判断与页面跳转
      this.handleRoleAndRedirect(decryptedData)
      this.getClass()
    },

    handleError (message) {
      const hide = this.$message.loading('登录中..', 0)
      setTimeout(() => {
        hide()
        clearUserInfo()
        this.$notification.error({
          message: '提示',
          description: message
        })
      }, 500)
    },

    handleRoleAndRedirect (decryptedData) {
      this.$notification.success({
        message: '提示',
        description: '正在加载中'
      })
      setTimeout(() => {
        //  decryptedData.user.roleLis是空的话，弹窗提示角色信息为空
        // if (decryptedData.user.roleList.length === 0) {
        //   this.$notification.error({
        //     message: '提示',
        //     description: '角色信息为空'
        //   })
        //   return
        // }
        const roleFlag = decryptedData.user.roleList.find(item => item.roleName === 'ROLE_TEACHER_ENT')
        window.location.href = roleFlag ? this.$stuUrl + 'tea' : this.$stuUrl + 'stu'
      }, 1000)
    },
    setCookieIfChecked (mobileOrEmail, passwordOrCode) {
      if (this.checked) {
        this.setCookie(mobileOrEmail, passwordOrCode, 7)
      } else {
        this.clearCookie()
      }
    },

    getPublicSecret () {
      // 获取加密公钥
      const _this = this
      axios({
        url: this.$loginServer + 'auth/res/getPublicSecret'
      }).then((res) => {
        // console.log(res)
        if (res && res.data && res.data.resultCode == 0) {
          _this.encryptionKey = res.data.resultData
          window.localStorage.setItem('encryptionKey', _this.encryptionKey)
          // console.log(_this.encryptionKey)
        }
      })
    },
    getClass () {
      const param = {
        access_token: '',
        userId: this.userId
      }
      axios({
        url: this.$baseServer + 'ebook/class/common/getMyClassInfo',
        method: 'POST',
        data: param
      }).then((res) => {
        setUserInfo(
          Object.assign(getUserInfo(), {
            class: res.data.resultData || []
          })
        )
      })
    },
    // 设置cookie
    setCookie (cName, cPwd, exdays) {
      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        'username' + '=' + cName + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie =
        'password' + '=' + cPwd + ';path=/;expires=' + exdate.toGMTString()
    },
    // 读取cookie
    getCookie () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ') // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=') // 再次切割
          // 判断查找相对应的值
          if (arr2[0] == 'username') {
            this.form.username = arr2[1] // 保存到保存数据的地方
          } else if (arr2[0] == 'password') {
            this.form.password = arr2[1]
          }
        }
      }
    },
    // 清除cookie
    clearCookie () {
      this.setCookie('', '', -1) // 修改2值都为空，天数为负1天就好了
    },
    goAgree () {
      window.open('https://e-snails.com/privacy/index.html', '__blank')
    },
    goSecret () {
      window.open('https://e-snails.com/privacy/yszc.html', '__blank')
    },
    getHeight () {
      const windowHeight = window.innerHeight - 60 - 293
      document.getElementById('content').style.height = windowHeight + 'px'
      if (windowHeight - 480 > 0) {
        document.getElementById('login').style.marginTop = (windowHeight - 480) / 2 + 'px'
      } else {
        document.getElementById('login').style.marginTop = 10 + 'px'
      }
      if (windowHeight - 370 > 0) {
        document.getElementById('login_img').style.marginTop = (windowHeight - 370) / 2 + 'px'
      } else {
        document.getElementById('login_img').style.marginTop = 65 + 'px'
      }
      document.getElementById('login_img').style.marginLeft = (windowHeight) / 1.5 + 'px'
    },
    getUserInfo,
    setUserInfo
  },
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="less" scoped>
.ant-layout-header,
.ant-layout-content,
.ant-layout-footer {
  margin: 0;
  padding: 0;
  background: #404040;
}

.ant-layout-header {
  height: 64px;
  border-bottom: 1px solid rgba(61, 61, 61, 0.15);
  box-sizing: border-box;
}

.login-container {
  width: 100%;
  min-height: 500px;
  // background-image: url("../../assets/img/bg_login_pure.jpeg") ;
  background-image: url("../../assets/img/login-bg-imp.png");
  background-size: 100% 100%;
  height: auto;
  overflow: auto;

  .login-img_bg {
    width: 119px;
    height: 370px;
    position: absolute;
    background-image: url('../../assets/img/login-bg-text.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 160px;
    margin-left: 480px;
  }

  .login-form {
    width: 400px;
    height: 480px;
    background-image: url("../../assets/img/bg_login_form.png");
    background-size: 100% 100%;
    padding: 40px 50px 60px 50px;
    position: absolute;
    margin-top: 120px;
    margin-right: -10px;
    right: 0px;
    // background: #fff;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      text-align: center;
      margin-bottom: 15px;
    }

    .img1 {
      width: 17px;
      height: 14px;
    }

    .img2 {
      width: 16px;
      height: 16px;
    }

    .remember {
      margin-top: 0px;
    }
  }

  /deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 35px;
  }

  .forge-password {
    color: blue;
  }

  .user-login {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee9de;

    .register {
      color: blue;
    }
  }

  .user-login-other {
    text-align: center;
    padding-top: 15px;

    .chat {
      color: #03cf65;
      margin-left: 10px;
    }
  }

  .agree {
    text-align: center;
    font-size: 12px;

    .text1 {
      color: #bcb8b3;
    }

    .text2 {
      color: #000;
      cursor: pointer;
    }
  }
}

/* 登录按钮 */
.submit {
  width: 100%;
  height: 36px;
  font-size: 16px;
  margin-top: 40px;
}

/* 用户登录标题 */
.title {
  margin-bottom: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  font-family: Microsoft Yahei;
}

/* 输入框内左边距50px */
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 50px;
}

.ant-form-item-control {
  line-height: 45px;
}

.getCaptcha {
  width: 115px;
  height: 40px;
  font-size: 16px;
  background: #DB5647;
  color: #fff;
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px;
  margin: 0px;
  padding: 12px 30px;
}

/deep/ .ant-tabs-nav-container {
  text-align: center;
}

/deep/ .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 25px;
  z-index: 1;
  box-sizing: border-box;
  width: 95px !important;
  height: 2px;
  //background-color: #db5647;
  transform-origin: 0 0;
}

</style>
